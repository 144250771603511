import { Alert, Button } from "@e-seikatsu/design-system";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
} from "@mui/material";
import { textSx, OpenInNewLink } from "esa-components";
import { SUPPORT_SITE_PAGES } from "const/supportSite";

export type TermsOfServiceDialogProps = {
  open: boolean;
  onSubmit: () => Promise<void>;
  isLoggedIn?: boolean;
};

const TermsOfServiceDialog = ({
  open,
  onSubmit,
  isLoggedIn = false,
}: TermsOfServiceDialogProps): JSX.Element => (
  <Box>
    <Dialog fullWidth open={open}>
      <DialogTitle>利用規約変更のお知らせ</DialogTitle>
      <DialogContent>
        <Stack pt={2} spacing={2}>
          <Stack>
            <Typography variant="subtitle2">
              いい生活アカウントをご利用いただきありがとうございます。
            </Typography>
            <Typography variant="subtitle2">
              最新のアカウント利用規約をご確認ください。
            </Typography>
          </Stack>
          <Alert color="info">
            <Stack spacing={0.5}>
              <Typography variant="subtitle1" fontWeight="bold">
                主な変更内容
              </Typography>
              <Typography>
                「いい生活Square
                利用規約」のうちアカウント利用に関する事項を独立し
                以下の2種類の規約に変更します。
              </Typography>
              <Typography>・「いい生活アカウント規約」</Typography>
              <Typography>・「いい生活Square利用規約」</Typography>
            </Stack>
          </Alert>
          <Typography sx={{ ...textSx.sm, ...textSx.secondaryText }}>
            上記変更内容の詳細は、こちらの新しい利用規約（
            <OpenInNewLink href={SUPPORT_SITE_PAGES.esaTerms}>
              いい生活アカウント
            </OpenInNewLink>
            ）（
            <OpenInNewLink href={SUPPORT_SITE_PAGES.squareTerms}>
              いい生活Square
            </OpenInNewLink>
            ）をご確認ください。
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button type="button" onClick={onSubmit} variant="contained">
          {isLoggedIn
            ? "利用規約に同意して続ける"
            : "利用規約に同意してログイン"}
        </Button>
      </DialogActions>
    </Dialog>
  </Box>
);

export default TermsOfServiceDialog;
