import { Box, Button, Typography } from "@mui/material";
import { useIsMobile } from "esa-components";
import { Outlet } from "react-router-dom";
import { useVersion } from "hooks/useVersion";

const Snackbar = (): JSX.Element => {
  const isMobile = useIsMobile();

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection={isMobile ? "column" : "row"}
      columnGap={3}
      p={1.5}
      sx={{
        backgroundColor: "white",
        position: "fixed",
        zIndex: "99999",
        width: "100%",
      }}
    >
      <Box>
        <Typography>
          いい生活アカウント管理画面の最新版が公開されました。
        </Typography>
        <Typography fontWeight="bold">
          未保存のデータがある場合は破棄されますのでご注意ください。
        </Typography>
      </Box>
      <Button
        type="button"
        color="inherit"
        size="small"
        variant="outlined"
        onClick={(): void => window.location.reload()}
      >
        更新
      </Button>
    </Box>
  );
};

const RefreshVersionProvider = (): JSX.Element => {
  const { refreshRequired } = useVersion();

  if (refreshRequired) {
    return (
      <Box>
        <Snackbar />
        <Outlet />
      </Box>
    );
  }

  return <Outlet />;
};

export default RefreshVersionProvider;
