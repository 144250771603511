import { Box, Button } from "@mui/material";
import { ZodError } from "zod";
import { ERROR_CODE, AppError } from "esa-core";
import ErrorPanel from "components/ErrorPanel/ErrorPanel";
import { SUPPORT_SITE_PAGES } from "const/supportSite";

export type CommonErrorPanelProps = {
  error: Error;
  onClickReset?: () => void | Promise<void>;
  onClickTop?: () => void | Promise<void>;
  onClickLogout?: () => void | Promise<void>;
  refreshRequired?: boolean;
};

const CommonErrorPanel = ({
  error,
  onClickReset,
  onClickTop,
  onClickLogout,
  refreshRequired = false,
}: CommonErrorPanelProps): JSX.Element => {
  if (refreshRequired) {
    return (
      <ErrorPanel
        heading="ブラウザを再読込してください"
        subHeading={
          <div>
            <div>いい生活アカウント管理画面の最新版が公開されました。</div>
            <div>
              セキュリティの観点から、一時的にご利用いただけなくなっています。
            </div>
            <div>再読込いただくと、最新版に切り替わります。</div>
          </div>
        }
        action={
          <Box display="flex" gap={1.5}>
            {onClickReset && (
              <Button type="button" variant="outlined" onClick={onClickReset}>
                再読込
              </Button>
            )}
            {onClickTop && (
              <Button type="button" variant="contained" onClick={onClickTop}>
                Topへ
              </Button>
            )}
          </Box>
        }
      />
    );
  }

  if (error instanceof AppError) {
    switch (error.errorCode) {
      case ERROR_CODE.NETWORK_ERROR:
        return (
          <ErrorPanel
            heading="ネットワーク接続に失敗しました"
            subHeading={error.message}
            action={
              <Box display="flex" gap={1.5}>
                {onClickReset && (
                  <Button
                    type="button"
                    variant="outlined"
                    onClick={onClickReset}
                  >
                    再読込
                  </Button>
                )}
                {onClickTop && (
                  <Button
                    type="button"
                    variant="contained"
                    onClick={onClickTop}
                  >
                    Topへ
                  </Button>
                )}
              </Box>
            }
          />
        );
      case ERROR_CODE.AUTHORIZATION_ERROR:
        return (
          <ErrorPanel
            heading="認証に失敗しました"
            subHeading={error.message}
            action={
              <Box display="flex" gap={1.5}>
                {onClickReset && (
                  <Button
                    type="button"
                    variant="outlined"
                    onClick={onClickReset}
                  >
                    再読込
                  </Button>
                )}
                {onClickLogout && (
                  <Button
                    type="button"
                    variant="contained"
                    onClick={onClickLogout}
                  >
                    ログアウト
                  </Button>
                )}
              </Box>
            }
          />
        );
      case ERROR_CODE.NOT_FOUND_ERROR:
        return (
          <ErrorPanel
            heading="対象のデータが見つかりませんでした"
            subHeading={error.message}
            action={
              <Box display="flex" gap={1.5}>
                {onClickReset && (
                  <Button
                    type="button"
                    variant="contained"
                    onClick={onClickReset}
                  >
                    再読込
                  </Button>
                )}
              </Box>
            }
          />
        );
      case ERROR_CODE.CLIENT_ERROR:
      case ERROR_CODE.SERVER_ERROR:
      case ERROR_CODE.APPLICATION_ERROR:
      default:
        return (
          <ErrorPanel
            heading="システムエラーが発生しました"
            subHeading={error.message}
            action={
              <Box display="flex" gap={1.5}>
                {onClickReset && (
                  <Button
                    type="button"
                    variant="outlined"
                    onClick={onClickReset}
                  >
                    再読込
                  </Button>
                )}
                {onClickTop && (
                  <Button
                    type="button"
                    variant="contained"
                    onClick={onClickTop}
                  >
                    Topへ
                  </Button>
                )}
              </Box>
            }
          />
        );
    }
  }
  /*
  error instanceof ZodError のみでは正しくZodErrorを検出できない場合があるためnameでの判別を加えている
  参考：https://github.com/colinhacks/zod/issues/515
   */
  if (error instanceof ZodError || error.name === ZodError.name) {
    return (
      <ErrorPanel
        heading="現在ご利用いただけません"
        subHeading={
          <div>
            <div>
              エラーを検知したため、一時的にサービスをご利用いただけません。
            </div>
            <div>
              ご利用のお客様にはご不便をおかけして申し訳ございませんが、
            </div>
            <div>復旧完了までしばらくお待ち下さい。</div>
            <div>
              お急ぎの場合は、いい生活サポート窓口へお問い合わせください。
            </div>
          </div>
        }
        errorCode={ERROR_CODE.INVALID_DATA_ERROR}
        action={
          <Box display="flex" gap={1.5}>
            <Button
              type="button"
              variant="contained"
              href={SUPPORT_SITE_PAGES.inquiry}
              target="_blank"
              rel="noopener noreferrer"
            >
              お問い合わせ
            </Button>
          </Box>
        }
      />
    );
  }
  return (
    <ErrorPanel
      heading="システムエラーが発生しました"
      subHeading={error.message}
      action={
        <Box display="flex" gap={1.5}>
          {onClickReset && (
            <Button type="button" variant="outlined" onClick={onClickReset}>
              リセット
            </Button>
          )}
          {onClickTop && (
            <Button type="button" variant="contained" onClick={onClickTop}>
              Topへ
            </Button>
          )}
        </Box>
      }
    />
  );
};

export default CommonErrorPanel;
